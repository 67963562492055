<mat-toolbar theme *ngIf="this.isCookieEnabled && router.url !== '/logout'" class="mat-elevation-z2" color="primary">

  <button mat-icon-button (click)="ToolbarMenuToggle(); closeAllExpansionPanel()" class="burger-menu"
    *ngIf="!(router.url == '/login' || router.url == '/changepassword' || router.url == '/page-not-found' || router.url == '/back-office') && sharedVariables.showElementsLoggedIn === true">
    <mat-icon>menu</mat-icon>
  </button>
  <button mat-icon-button (click)="ToolbarMenuToggle()" class="burger-menu" *ngIf="router.url == '/back-office'">
    <mat-icon>menu</mat-icon>
  </button>

  <img class="pointer" [src]="this.sharedVariables.activeTheme.name !== 'dark' ? pathLogo : pathLogoLight" alt="logo"
    (click)="sharedVariables.showElementsLoggedIn === true ? goToHome() : ''"
    [ngStyle]="isDemo ? {'width': '5.2em'} : {}" *ngIf="getRes() > 800">
  <img class="pointer" [src]="pathLogoMini" alt="small-logo"
    (click)="sharedVariables.showElementsLoggedIn === true ? goToHome() : ''"
    [ngStyle]="isDemo ? {'width': '5.2em'} : {'width': '2.2em'}" *ngIf="getRes() <= 800">

  <div *ngIf="!isDemo" fxLayout="column" fxLayoutAlign="center start" class="powered mx-2">
    <span class="powered-text" style="color: white !important;">powered by</span>
    <span>
      <img src="./assets/logo/mini-logo-s2e.png" alt="logo-minimized" class="logo-s2e">
    </span>
  </div>


  <span class="example-spacer"></span>

  <span style="color: var(--icon-color-on-bg) !important" *ngIf="getRes() > 650" class="mx-1">PORTALE
    INTERMEDIARI</span>




  <div class="links" fxLayout="row" fxLayoutAlign="center center">

    <!-- TODO: impostazioni genrali da decidere -->
    <!-- <button *ngIf="sharedVariables.showElementsLoggedIn" mat-button (click)="openSettings()">
      <mat-icon>settings</mat-icon>
    </button> -->

    <button *ngIf="sharedVariables.showElementsLoggedIn === true" mat-button (click)="goToHome()">
      <mat-icon>home</mat-icon>
    </button>

    <button mat-button (click)="openHelpDialog()">
      <mat-icon>help</mat-icon>
    </button>

    <button *ngIf="sharedVariables.showElementsLoggedIn === true" mat-button [matMenuTriggerFor]="menu">
      <mat-icon>account_circle</mat-icon>
    </button>

    <mat-menu #menu="matMenu" style="color: white !important;">
      <button mat-menu-item (click)="openDatiUtenteDialog()">
        <mat-icon color="accent">
          account_circle
        </mat-icon>Dati Utente
      </button>

      <!-- <button mat-menu-item routerLink="back-office" *ngIf="router.url !== '/back-office'" >
        <mat-icon color="accent">
          admin_panel_settings
        </mat-icon>Amministrazione
      </button> -->

      <button *ngIf="!isDemo" mat-menu-item routerLink="back-office" (click)="goToChangePassword()">
        <mat-icon color="accent">
          vpn_key
        </mat-icon>Cambio password
      </button>

      <button mat-menu-item (click)="changeTheme();$event.stopPropagation()">
        <mat-icon color="accent">
          {{sharedVariables.activeTheme.name === "dark" ? "light_mode" : "dark_mode"}}
        </mat-icon>{{'Tema colori ' + (sharedVariables.activeTheme.name === 'dark' ? 'chiari' : 'scuri')}}
      </button>

      <button *ngIf="!isDemo" mat-menu-item (click)="logoutExe()">
        <mat-icon color="accent">
          logout
        </mat-icon>
        Logout
      </button>
    </mat-menu>

  </div>

</mat-toolbar>

<mat-toolbar theme *ngIf="router.url === '/logout'" class="mat-elevation-z2" color="primary">
  <img [src]="pathLogo" alt="logo" [ngStyle]="isDemo ? {'width': '5.2em'} : {}" *ngIf="getRes() > 800">

  <span class="example-spacer"></span>

  <span style="color: var(--icon-color-on-bg) !important" *ngIf="getRes() > 650" class="mx-1">PORTALE
    INTERMEDIARI</span>

  <div class="links" fxLayout="row" fxLayoutAlign="center center">

    <button mat-button (click)="openHelpDialog()">
      <mat-icon>help</mat-icon>
    </button>

  </div>

</mat-toolbar>

<!-- SIDENAV -->
<mat-sidenav-container fxFlexFill theme>
  <!-- SEZIONE STANDARD -->
  <!-- *ngIf="!(router.url.split('?')[0] == '/login' || router.url.split('?')[0] == '/changepassword' || router.url.split('?')[0] == '/page-not-found' || router.url.split('?')[0] == '/back-office')" -->
  <mat-sidenav
    *ngIf="sharedVariables.showElementsLoggedIn === true && sharedVariables.changePasswordForceStatus !== 'active' && sharedVariables.changePasswordStatus !== 'active' && sharedVariables.isBackOfficeActive !== 'active' && router.url !== '/logout'"
    style="background-color: whitesmoke;" #sidenav id="sidenav" mode="side" opened [class.menu-open]="isMenuOpen"
    [class.menu-close]="!isMenuOpen" [disableClose]="true">
    <mat-nav-list>
      <mat-list-item routerLinkActive="colored-margin-left" routerLink="dashboard">
        <div fxLayout="row" fxLayoutAlign="start center" routerLinkActive="active-link">
          <mat-icon>home</mat-icon>
          <div mat-list-item class="link-item">Dashboard</div>
        </div>
      </mat-list-item>

      <mat-list-item routerLinkActive="colored-margin-left" routerLink="polizze">
        <div fxLayout="row" fxLayoutAlign="start center" routerLinkActive="active-link">
          <mat-icon>list_alt</mat-icon>
          <div mat-list-item class="link-item">Polizze</div>
        </div>
      </mat-list-item>

      <mat-list-item *ngIf="library_investimenti_available" routerLinkActive="colored-margin-left" routerLink="fondi">
        <div fxLayout="row" fxLayoutAlign="start center" routerLinkActive="active-link">
          <mat-icon>assessment</mat-icon>
          <div mat-list-item class="link-item">Fondi</div>
        </div>
      </mat-list-item>

      <!-- OLD LINK: <a href="http://prodotti.intesasanpaolovita.it/investimento-e-risparmio/prodotti-e-performance/performance"></a> -->
      <!-- OLD LINK: <a href="http://prodotti.intesasanpaolovita.it/" -->
        <a href="http://prodotti.intesasanpaoloassicurazioni.com/"
        target="_blank">
        <mat-list-item *ngIf="!isDemo" routerLinkActive="colored-margin-left">
          <div fxLayout="row" fxLayoutAlign="start center" routerLinkActive="active-link">
            <mat-icon>info</mat-icon>
            <div mat-list-item class="link-item">Repository</div>
          </div>
        </mat-list-item>
      </a>

      <mat-divider>
      </mat-divider>

      <mat-list-item *ngIf="library_investimenti_available" routerLinkActive="colored-margin-left" routerLink="faq">
        <div fxLayout="row" fxLayoutAlign="start center" routerLinkActive="active-link">
          <mat-icon>picture_as_pdf</mat-icon>
          <div mat-list-item class="link-item">Manuali e FAQ</div>
        </div>
      </mat-list-item>

      <mat-divider></mat-divider>

      <!-- <mat-list-item routerLink="prodotti">
        <div fxLayout="row" fxLayoutAlign="start center" routerLinkActive="active-link">
          <mat-icon>list_alt</mat-icon>
          <div mat-list-item class="link-item">Prodotti</div>
        </div>
      </mat-list-item> -->

      <div *ngIf="checkRouteDettaglioPolizza(router.url)">

        <mat-chip-list style="padding: 1em;" class="mat-chip-list-stacked" aria-label="Color selection">
          <mat-chip *ngFor="let chip of [chipList[0]]" (click)="scroll(chip.library)"
            style="margin-left: 1.5em !important; transform: scale(0.9); cursor: pointer;" selected
            class="mat-elevation-z4">
            <mat-icon *ngIf="chip.library == 'Dettaglio-Polizza'">{{chip.icon}}</mat-icon>
            <div *ngIf="chip.library == 'Dettaglio-Polizza'" [ngClass]="isMenuOpen ? 'd-block' : 'd-none'"
              style="margin-left: 0.5em;">
              Dettaglio Polizza
            </div>
          </mat-chip>

          <mat-card-header *ngIf="isMenuOpen" style="margin-left: 1em;">
            <mat-card-subtitle *ngIf="!modPolizzeAggregate"
              style="margin-bottom: 0; margin-left: 0.5em; padding: 0.5em;">Polizza nº
              <strong>{{nPolizza}}</strong></mat-card-subtitle>
            <mat-card-subtitle *ngIf="modPolizzeAggregate"
              style="margin-bottom: 0; margin-left: 0.5em; padding: 0.5em; max-height: calc(100vh/6.5); overflow: auto; direction: rtl;">
              <div style="margin-bottom: 1em; direction: ltr;" *ngFor="let item of arrIdentificatori" fxLayout="row"
                fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="center center" [ngStyle]="{'background-color': item.color}"
                  class="mat-elevation-z4 identificatore-polizze">{{item.text}}</div>
                <strong>{{item.numPolizza}}</strong>
              </div>
            </mat-card-subtitle>
          </mat-card-header>

          <mat-chip *ngFor="let chip of chipList | slice:1" (click)="scroll(chip.library)"
            style="margin-left: 1.5em !important; transform: scale(0.9); cursor: pointer;" selected
            class="mat-elevation-z4">
            <mat-icon *ngIf="chip.library != 'Dettaglio-Polizza'">{{chip.icon}}</mat-icon>
            <div *ngIf="chip.library == 'Analisi-Portafoglio'" [ngClass]="isMenuOpen ? 'd-block' : 'd-none'"
              style="margin-left: 0.5em;">
              Analisi Portafoglio
            </div>
            <div *ngIf="chip.library != 'Dettaglio-Polizza' && chip.library != 'Analisi-Portafoglio'"
              [ngClass]="isMenuOpen ? 'd-block' : 'd-none'" style="margin-left: 0.5em;">
              {{chip.library}}
            </div>
          </mat-chip>
        </mat-chip-list>

      </div>

    </mat-nav-list>

    <mat-accordion #accordion="matAccordion">
      <mat-expansion-panel [expanded]="true" class="mat-elevation-z0" style="background-color: transparent !important;"
        *ngIf="polizze_preferite.length > 0">
        <mat-expansion-panel-header *ngIf="isMenuOpen">
          <mat-panel-title>
            Preferiti
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-expansion-panel-header (click)="ToolbarMenuToggle()" *ngIf="!isMenuOpen">
          <mat-panel-title>
            <mat-icon>star</mat-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-nav-list id="history-box">
          <!-- <mat-card-header *ngIf="isMenuOpen">
            <mat-card-subtitle style="margin-bottom: 0.2em; margin-top: 0.6em;">Preferiti</mat-card-subtitle>
          </mat-card-header> -->
          <div
            [ngClass]="!(router.url.split('?')[0] == '/dashboard' || router.url.split('?')[0] == '/polizze' || router.url.split('?')[0] == '/fondi') && (modPolizzeAggregate || getHeight() < 850) ? 'sidenav-container-con-aggregati' : 'sidenav-container'">
            <mat-list-item *ngFor="let pref of polizze_preferite; let i = index"
              (click)="navigateFromPreferiti(pref.polizze_preferite, $event)"
              (auxclick)="navigateFromPreferiti(pref.polizze_preferite, $event)">
              <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon class="sidenav-icon" (click)="editPolizzePreferite(i); $event.stopPropagation()"
                    matTooltip="{{ pref.descrizione }}" matTooltipPosition="after" [matTooltipDisabled]="isMenuOpen">
                    edit
                  </mat-icon>
                  <mat-icon class="sidenav-icon"
                    (click)="confirmDeletePolizzePreferite(pref.id, true); $event.stopPropagation()">
                    delete
                  </mat-icon>
                </div>
                <div class="link-item-history one-line"><span style="font-size: 14px">{{pref.descrizione}}</span></div>
              </div>
            </mat-list-item>
          </div>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-divider></mat-divider>

      <mat-expansion-panel class="mat-elevation-z0" [expanded]="polizze_preferite.length === 0"
        style="background-color: transparent !important;" *ngIf="portafoglioHistory.length > 0">
        <mat-expansion-panel-header *ngIf="isMenuOpen">
          <mat-panel-title>
            Cronologia
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-expansion-panel-header (click)="ToolbarMenuToggle()" *ngIf="!isMenuOpen">
          <mat-panel-title>
            <mat-icon>settings_backup_restore</mat-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-nav-list *ngIf="historyNotEmpty" id="history-box">
          <!-- <mat-card-header *ngIf="isMenuOpen">
            <mat-card-subtitle style="margin-bottom: 0.2em; margin-top: 0.6em;">Cronologia</mat-card-subtitle>
          </mat-card-header> -->
          <div
            [ngClass]="!(router.url.split('?')[0] == '/dashboard' || router.url.split('?')[0] == '/polizze' || router.url.split('?')[0] == '/fondi') && (modPolizzeAggregate || getHeight() < 850) ? 'sidenav-container-con-aggregati' : 'sidenav-container'">
            <mat-list-item *ngFor="let item of portafoglioHistory; let i=index" (click)="
                resetDashboardSubcriptionState(item.compagnia, item.nPolizza);
                navigateFromSidenav(item.compagnia, item.nPolizza, $event)"
              (auxclick)="navigateFromSidenav(item.compagnia, item.nPolizza, $event)"
              routerLinkActive="active-list-item" class="link-text">
              <div fxLayout="row" fxLayoutAlign="start center">
                <button mat-icon-button style="width: 24px!important;"
                  [disabled]="addPreferitiIsAvailable === false || deletePreferitiIsAvailable === false" (click)="
                    clickPreferiti(i, false);
                    $event.stopPropagation();
                    $event.preventDefault()">
                  <mat-icon class="sidenav-icon"
                    matTooltip="{{ item.cognome }} {{ item.nome }} {{ formatNumPolizza(item.nPolizza) }}"
                    matTooltipPosition="after" [matTooltipDisabled]="isMenuOpen">
                    {{item.preferita ? "star" : "star_outline"}}
                  </mat-icon>
                </button>
                <mat-icon class="sidenav-icon" (click)="confirmDeleteContact($event, i)">delete</mat-icon>
                <div class="link-item-history one-line"><span style="font-size: 14px; margin-right: 20em;"> {{
                    item.cognome }} {{ item.nome }} {{ formatNumPolizza(item.nPolizza) }}</span></div>
              </div>
            </mat-list-item>
          </div>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-divider></mat-divider>
    </mat-accordion>

    <div *ngIf="isMenuOpen && sharedVariables.statusReady && getHeight() > 900">
      <div class="sub_div mat-elevation-z8" fxLayout="column" fxLayoutAlign="start start">
        <strong>ULTIMO AGGIORNAMENTO:</strong>
        <span>{{ dataUltimoAggiornamento }}</span>
      </div>
    </div>

  </mat-sidenav>

  <!-- SEZIONE BACK-OFFICE -->
  <mat-sidenav style="background-color: whitesmoke;" #sidenav id="sidenav" mode="side" opened
    [class.menu-open]="isMenuOpen" [class.menu-close]="!isMenuOpen" [disableClose]="true"
    *ngIf="router.url.split('?')[0] == '/back-office'">
    <mat-nav-list *ngIf="sharedVariables.statusReady">
      <mat-list-item routerLinkActive="colored-margin-left" routerLink="">
        <div fxLayout="row" fxLayoutAlign="start center" routerLinkActive="active-link">
          <mat-icon>edit_note</mat-icon>
          <div mat-list-item class="link-item">Modifica Prodotti</div>
        </div>
      </mat-list-item>
    </mat-nav-list>

    <div *ngIf="isMenuOpen && sharedVariables.statusReady && getHeight() > 900">
      <div class="sub_div mat-elevation-z8" fxLayout="column" fxLayoutAlign="start start">
        <strong style="color: white !important;">ULTIMO AGGIORNAMENTO:</strong>
        <span style="color: white !important;">{{dataUltimoAggiornamento | date:
          settingsServ.setting_config.date_settings.date_format }}</span>
      </div>
    </div>

  </mat-sidenav>


  <mat-sidenav-content [ngStyle]="{ 'margin-left.px': contentMargin }" class="sidenav-scrollbar" id="sidenav-content"
    [ngClass]="router.url === '/fondi' ? 'mat-sidenav-content-investimenti' : '' ">
    <!-- Appare quando i cookie del browser sono disabilitati -->
    <div *ngIf="!this.isCookieEnabled" class="cookie-container" fxLayout="column" fxLayoutAlign="start center">
      <mat-card>
        <div fxLayout="column" fxLayoutAlign="center center">
          <h1 class="pippo">Sembra che i cookie del tuo browser siano disabilitati.</h1>
          <h2>Abilitali per poter accedere al Portale degli Intermediari.</h2>
        </div>
        <div>
          <span>Hai bisogno di aiuto?</span>
          <ul>
            <li><a href="https://support.google.com/accounts/answer/61416?hl=it&co=GENIE.Platform%3DDesktop"
                target="_blank">Abilitare i cookie in Chrome</a></li>
            <li><a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie" target="_blank">Abilitare i cookie
                in Firefox</a></li>
            <li><a href="https://support.apple.com/it-it/guide/safari/ibrw850f6c51/mac" target="_blank">Abilitare i
                cookie in Safari</a></li>
            <li><a href="https://help.opera.com/en/latest/web-preferences/" target="_blank">Abilitare i cookie in
                Opera</a></li>
          </ul>
          <h3>Dopo averli abilitati, aggiorna la pagina.</h3>
          <button class="refresh-button" mat-raised-button style="width: 100%;"
            (click)="refreshButton()">Aggiorna</button>
        </div>
      </mat-card>
    </div>

    <div *ngIf="settingsReady" class="container" id="graphicsOffsetTarget">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
